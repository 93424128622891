import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import Redirect from 'react-router-dom/Redirect';

import DeviceRequest from "./DeviceRequest";

//const apiUrl = 'http://atlas:8090/lx-atlas/api/';
const apiUrl = 'https://api.locatorx.com/lx-atlas/api/';
//const apiUrl = 'https://api-staging.locatorx.com/lx-atlas/api/';
//const apiUrl = 'https://api-dev.locatorx.com/lx-atlas/api/';

const csmToken = 'e85cb246-7f50-44e1-a381-b7cf5516a231';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    }
  }
  render() {
    const NoAuthRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={props => (
        <Component {...props} apiUrl={apiUrl} token={csmToken} />
      )}/>
    )
    return (
      <Router>
        <div>
          <NoAuthRoute exact path="/" component={DeviceRequest} /> 
        </div>
      </Router>
    );
  }
  logout() {
    localStorage.removeItem('token');
    return (<Redirect to="/" />);
  }

  PrivateRoute = ({
    component: Component,
    ...rest
    }) => (
      <Route {...rest} render={(props) => (
        localStorage.getItem('token') != null
          ? 
          <Component {...props} apiUrl={apiUrl} token={localStorage.getItem('token')} />
          :
        <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
              }} />
        )
        }
    />
  )
}